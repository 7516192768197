<template>
  <div>
    <WeInput
      class="mb-0"
      v-bind:input="computedData.unmask || computedData.price"
      v-on:input="onUpdate"
      v-bind:filter-number="true"
      v-on:on-focus="$refs.inputRef.$refs.input.select()"
      ref="inputRef"
    />
  </div>
</template>
<script>
export default {
  name: "TablePrice",
  props: {
    value: {
      default: () => {},
    },
    size: {
      default: "",
    },
    column: {
      default: () => {},
    },
  },
  model: {
    prop: "value",
  },
  methods: {
    onUpdate(value) {
      this.computedData.price = value;
      this.computedData.unmask = value;
    },
  },
  computed: {
    computedData() {
      return this.value;
    },
  },
};
</script>
